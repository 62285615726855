div#header {
    background: -webkit-radial-gradient(
        #1d3582,
        #101420
    ); /* Safari 5.1 to 6.0 */
    background: -o-radial-gradient(
        #1d3582,
        #101420
    ); /* For Opera 11.6 to 12.0 */
    background: -moz-radial-gradient(
        #1d3582,
        #101420
    ); /* For Firefox 3.6 to 15 */
    background: radial-gradient(#1d3582, #101420); /* Standard syntax */
    height: 94px;
    padding: 5px 0 0 10px;
}
#logo {
    float: left;
}
div#header div.dbp {
    float: left;
    margin-left: 10px;
    vertical-align: top;
    color: #fefeff;
    font-size: 46pt;
    font-family: Verdana, sans-serif;
    font-variant: small-caps;
    font-weight: 100;
    text-shadow: 3px 3px #00003f;
}

div#subhead {
    float: right;
    padding-top: 8px;
    padding-right: 8px;
}

div.pageTitle {
    vertical-align: top;

    font-family: Arial Black, sans-serif;
    font-size: 24pt;
    color: #fcfcff;
}

div.pageSubTitle {
    font-family: Verdana, sans-serif;
    font-size: 10pt;
    text-align: right;
    color: #fcfcff;
    padding: 0 2px 0 0;
    border-top: 2px solid #bab891;
}

#authBar {
    border-top: 3px solid #25587e;
    border-bottom: 3px solid #25587e;
    font-size: 14px;
    margin-top: 3px;
    padding: 4px;
    text-align: right;
}

#authBar a {
    background: #ffffff;
    color: #145192;
}

#main {
    text-align: center;
    padding: 10px;
    padding-bottom: 60px;
}

.crumb-bar-wrapper {
    width: 800px;
    padding-top: 15px;

    margin: auto;
}

.crumb-bar {
    font-size: 13px;
    font-weight: bold;

    padding-bottom: 4px;
    float: left;
}

.crumb-bar a {
    color: #024769;
    text-decoration: underline;
}

#searchBar {
    width: 800px;
    margin: auto;
}
#searchboxWrapper {
    float: right;
    padding: 15px;
    padding-bottom: 4px;
}

input#searchbox {
    border: 1px solid rgb(170, 170, 170);
    padding: 3px;
}

.ribbon-menu {
    /* display: inline; */
    position: relative;
    padding: 5px;
    width: 100%;
    text-align: center;
    vertical-align: middle;
}
.ribbon-menu .menu-item {
    display: inline-block;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 25px;
    border: 2px solid #8888FF;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 1px;
    margin-left: 1px;
    
    vertical-align: middle;
    text-align: center;
}
.menu-item a {
    text-decoration: none;
}

footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 40px;

    background: #145192;
    color: #ffffff;
    font-size: 12px;
    text-align: center;
    padding-top: 12px;
    
}

footer a {
    color: #ffffff;
    text-decoration: underline;
}
.footer-info {
    margin-top: 0.5em;
    font-style: italic;
    color: #999999;
}
h3 {
    color: #333;
    font-size: 20px;
    font-weight: normal;
    text-decoration: underline;
    text-transform: uppercase;
}
div.admin-content-pane {
    margin: 10px auto;
    min-width: 900px;
    width: 80%;
    text-align: left;
}

div.admin-content-pane a {
    color: #145192;
}
.rdt_TableCell {
    text-overflow: unset;
}


.rdt_TableCol, .rdt_TableCol_Sortable > span, .rdt_TableCol_Sortable > div {
    background-color: #1d3582;
    color: white;
    font-weight: bold;
    font-size: 12pt;
}



div.content-pane {
    margin: 10px auto;
    width: 800px;
    text-align: left;
}
div.content-pane a {
    color: #145192;
}
#portlet-container {
    margin: 10px auto;
    width: 800px;
    text-align: left;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

#portlet-container a {
    color: #145192;
}

#portal-intro {
    font-size: 16px;
    line-height: 1.2em;
    padding: 0 15px 15px 15px;
}

#portal-intro div.spacer-line {
    background-color: #024769;
    height: 3px;
    margin: 5px 0;
}

div.destination {
    background: #95cbe9;
    border: 1px solid #145192;
    color: #145192;
    float: left;
    font-size: 16px;
    height: 70px;
    margin: 10px;
    padding: 0;
    width: 378px;
    border-radius: 8px;
    -moz-border-radius: 8px;
    -webkit-border-radius: 8px;
}

div.destination a {
    color: #000000;
    display: block;
    height: 40px;
    padding: 15px;
    text-decoration: underline !important;
}

div#portlet-tagline {
    background: #f2f4ea;
    border: 1px solid #333333;
    color: #333333;
    font-size: 24px;
    font-weight: bold;
    margin: 10px;
    padding: 15px;
    width: 746px;
    border-radius: 8px;
    -moz-border-radius: 8px;
    -webkit-border-radius: 8px;
}

div#sitemap ul {
    list-style-type: circle;
    padding-left: 25px;
}

div#sitemap a {
    text-decoration: underline;
}

div.searchResult {
    margin: 15px 0;
}

div.searchResult span.title {
    font-size: 16px;
}

div.searchResult p.description {
    margin: 3px 0;
}

div.searchResult cite {
    color: #388222;
    font-style: normal;
    word-wrap: break-word;
}

.italic12pt {
    font-style: italic;
    font-size: 12px;
}

input[type='text'],
input[type='number'],
input[type='password'],
input[type='tel'],
input[type='email'],
select {
    background: #ffffff;
    border: 1px solid #aaaaaa;
    color: #000000;
    font-size: 14px;
    padding: 5px;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
}

input[type='text']:focus,
input[type='password']:focus {
    background: #fafafa;
    border: 1px solid #000000;
    color: #000000;
}

div.portlet {
    background: #95cbe9;
    border: 1px solid #145192;
    color: #145192;
    float: left;
    font-size: 16px;
    height: 165px;
    margin: 10px;
    padding: 0;
    width: 244px;
    border-radius: 8px;
    -moz-border-radius: 8px;
    -webkit-border-radius: 8px;
}

div.portlet a {
    color: #145192;
    display: block;
    height: 135px;
    padding: 15px;
    text-decoration: none;
}

div.portlet span.title {
    font-size: 20px;
    font-weight: bold;
    /* line-height: 32px; */
    vertical-align: 25%;
}

div.portlet span.description {
    display: block;
    margin: 15px 0;
}

div.portlet-action {
    height: 60px;
    text-align: center;
    width: 178px;
}

div.portlet-action a {
    color: #145192;
    display: block;
    height: 30px;
    padding: 15px;
    text-decoration: none;
}

div.portlet-action span.title {
    font-size: 22px;
}

div.portlet img {
    border: 0;
}

div.clear {
    clear: both;
}

table.formTable {
    width: 100%;
}

table.formTable td {
    vertical-align: top;
}

td.tdLabel {
    padding-right: 10px;
}

td.tdLabel label.label,
td.tdLabel label.errorLabel {
    font-weight: bold;
}

span.errorMessage {
    color: red;
    font-size: 13px;
    font-weight: bold;
}

span.successMessage {
    color: darkgreen;
    font-size: 13px;
    font-weight: bold;
}

td.tdLabel span.required {
    color: red;
    font-weight: bold;
}

.required {
    color: red;
    font-weight: bold;
}

ul.errorMessage {
    color: red;
    font-size: 13px;
    font-weight: bold;
}

fieldset {
    background: #efefef;
    border: 1px dotted #aaa;
    margin-top: 15px;
}

legend {
    background: #fcfcfc;
    border: 1px dotted #aaa;
    font-weight: bold;
    padding: 3px 8px;
}

div.inline-center {
    text-align: center;
}

.ui-state-highlight {
    background: #ffffa8 none repeat scroll 0 0;
}

.odd {
    text-align: center;
}

.even {
    text-align: center;
}

.wwFormTable tr {
    padding: 15px;
}

.formField.invalid input,
.formField.invalid select {
    border: 1px solid red;
}
.validationMessage {
    color: red;
    font-weight: bold;
}

table.userSearchResults {
    border-spacing: 0;
    border-collapse: collapse;
    border: 1px solid black;
}
table.userSearchResults th {
    text-align: center;
}
table.userSearchResults th,
table.userSearchResults td {
    margin: 0;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border: 1px solid black;
}

table.userSearchResults tr.odd td {
    background-color: #d0d0ff;
}
table.userSearchResults tr.even td {
    background-color: #a0b6db;
}

table.userSearchResults th {
    background-color: midnightblue;
    color: #ffffff;
    font-weight: bold;
}

.userSearchButton {
    margin-bottom: 1em;
}

.btn-lg {
    padding: 1em;
    font-weight: bold;
}

.icon-picker-title {
    padding: 5px;
    font-weight: bold;
}

.icon-picker-content {
    border: 2px inset;
    max-height: 300px;
    overflow-y: scroll;
}

.icon-picker-option {
    display: inline-block;
    padding: 5px;
}

.icon-picker-option:hover {
    background-color: lightblue;
}

.icon-picker-option.selected {
    background-color: blue;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
    -moz-appearance: textfield; /* Firefox */
}
