html,
body {
    height: 100%;
    font-family: 'Source Sans Pro', sans-serif;
    background-color: #ffffff;
    color: #000000;
    font-size: 14px;
    margin: 0;
    padding: 0;
}
#root {
  min-height:100%;
  position:relative;
}